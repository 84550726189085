<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검년월(예정, 완료) -->
          <c-datepicker
            label="점검년월"
            name="month"
            type="month"
            default="today"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="MIM_CHECK_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="진행상태"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            :editable="editable"
            :isFirstValue="false"
            type="search"
            label="점검부서"
            name="checkDeptCd"
            v-model="searchParam.checkDeptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안전점검일지 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :checkClickFlag="false"
      rowKey="safetyCheckId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="등록" v-if="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "safety-check",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "사업장",
            align: "center",
            sortable: false,
          },
          {
            name: "safetyCheckName",
            field: "safetyCheckName",
            style: 'width:200px',
            label: "점검명",
            align: "left",
            type: "link",
            sortable: false,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            label: "점검부서",
            align: "center",
            sortable: false,
          },
          {
            name: "checkStatusName",
            field: "checkStatusName",
            label: "진행단계",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            label: "점검월",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        month: '',
        lineId: '',
        utilityFlag: 'N',
        checkDeptCd: '',
      },
      period: [ '', ''],
      editable: true,
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.safetycheck.plan.list.url;
      // this.searchParam.month = this.$comm.getThisMonth();
      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.param = {
        safetyCheckId: row ? row.safetyCheckId : '',
      };
      this.popupOptions.title = '안전점검일지 상세'; 
      this.popupOptions.target = () =>
        import(`${"./safetyCheckDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    onItemClick(type) {
      this.linkClick(null);
    },
    
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      this.getList();
    },
  },
};
</script>